import React, { Component } from "react"
import Layout from '../layout/layout'
import Latest from '../components/latest_posts/index'
import './404.scss';

class Page404 extends Component {
  render() {
  

    return (
      <Layout>
        <div className="NotFound">
            <h1>Sorry Can't find your page.</h1>
            <h2>Click on a article below or navigate to a new page</h2>
        </div>

        <Latest/>
      </Layout>
    )
  }
}

export default Page404

