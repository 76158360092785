import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Latest from './latest_posts.js'
import "./style.scss"
// import Img from "gatsby-image"

const RecentData = () => (
  <StaticQuery
    query={graphql`
    {
      allWordpressPost(limit: 8, filter: {}, sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            slug
            excerpt
            images {
              squareFeaturedImage {
                localFile {
                  childImageSharp {
                    fixed {
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    `}
    render={data => <Latest data={data.allWordpressPost.edges} />}
  ></StaticQuery>
)

export default RecentData
